import React from 'react';
import styled from 'styled-components';
import Visibility from './Visibility';

const InputWrapper = styled.div`
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
`;

const Input = styled.textarea`
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: 0.2px;
    color: ${props => props.theme.color.slateGrey};
    padding: 4px 12px;
    padding-right: ${props => (props.type === 'password' ? '48px' : '12px')};
    width: 100%;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => (props.hasError ? props.theme.color.fadedRed : props.theme.color.silver)};
    transition: border-color ease 0.25s;
    -moz-appearance: none;
    -webkit-appearance: none;

    &:hover {
        border-color: ${props => (props.hasError ? props.theme.color.fadedRed : props.theme.color.silver)};
        outline: none;
    }

    &:focus {
        border-color: ${props => props.theme.color.darkPeriwinkle};
        box-shadow: inset 0 0 1px 1px rgba(106, 119, 213, 0.32);
        outline: none;
    }

    &::-ms-reveal,
    &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }
`;

const ToggleIcon = styled.div`
    position: absolute;
    right: 16px;
    top: 6px;
    cursor:  pointer;
`;

class TextArea extends React.Component {
  constructor(props) {
    super(props);

    this.onShowClick = this.onShowClick.bind(this);
    const { type } = this.props;

    this.state = {
      show: false,
      stateType: type,
    };
  }

  onShowClick() {
    const { show, stateType } = this.state;
    const newStateType = stateType === 'text'
      ? 'password'
      : 'text';

    this.setState(state => ({ ...state, ...{ show: !show, stateType: newStateType } }));
  }

  render() {
    const { name, id, onInput, onChange, onBlur, onFocus, cref, type, hasError } = this.props;
    const { show, stateType } = this.state;

    return (
      <InputWrapper>
        {type === 'password'
          ? (
            <ToggleIcon
              data-test={`eye-icon-${name}`}
              onClick={this.onShowClick}
              onMouseDown={e => e.preventDefault()}
            >
              <Visibility show={show} />
            </ToggleIcon>
          ) : ('')}
        <Input
          {...this.props}
          hasError={hasError}
          type={stateType}
          name={name}
          id={id}
          onInput={onInput}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          ref={cref}
        />
      </InputWrapper>
    );
  }
}

export default TextArea;
