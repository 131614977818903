import React from 'react';
import cl from 'classnames';
import { withNamespaces } from 'react-i18next';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import './ContactUsBody.scss';
import Recaptcha from 'react-google-recaptcha';

import Grid from '../Grid';
import Cell from '../Cell';

import InputField from '../InputFiled';
import TextArea from '../TextArea';
import Api from '../../utils/api';
import FiledError from '../FieldError';
import BackendErrors from '../../utils/backendErrors';
import SiteTitle from '../PageHeaderTitle';

class ContactUsBody extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });

    this.backendErrors = new BackendErrors(this.api, props.i18n);

    this.state = {
      message: {
        value: '',
        showError: false,
        dirty: false,
        valid: false,
      },
      email: {
        value: '',
        showError: false,
        dirty: false,
        valid: false,
      },
      subject: {
        value: '',
        showError: false,
        dirty: false,
        valid: false,
      },
      isSubmitted: false,
      isError: false,
      successSend: false,
      errors: {
        email: [],
        message: [],
        subject: [],
      },
    };

    this.onEmailInput = this.onEmailInput.bind(this);
    this.onSubjectInput = this.onSubjectInput.bind(this);
    this.onMessageInput = this.onMessageInput.bind(this);
    this.onEmailFocus = this.onEmailFocus.bind(this);
    this.onSubjectFocus = this.onSubjectFocus.bind(this);
    this.onSubjectBlur = this.onSubjectBlur.bind(this);
    this.onMessageFocus = this.onMessageFocus.bind(this);
    this.onEmailBlur = this.onEmailBlur.bind(this);
    this.onMessageBlur = this.onMessageBlur.bind(this);
    this.resetSubmit = this.resetSubmit.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onCaptchaExpired = this.onCaptchaExpired.bind(this);
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      this.checkUserLogin();
    }
  }

  checkUserLogin() {
    const config = this.api.statusConfig();

    axios(config).then(response => {
      const isAuthenticated = get(response, 'data.authenticated');
      let email = '';

      if (isAuthenticated) {
        email = get(response, 'data.email');
      }

      this.setState(oldState => merge(oldState, {
        isAuthenticated,
        email: { value: email },
      }));
    }).catch(e => {
    });
  }

  onEmailFocus() {
    this.validateEmail();
    this.setState(oldState => merge(oldState, {
      email: { dirty: true, showError: false },
    }));
  }

  onSubjectFocus() {
    this.validateSubject();
    this.setState(oldState => merge(oldState, {
      subject: { dirty: true, showError: false },
    }));
  }

  onMessageFocus() {
    this.validateMessage();
    this.setState(oldState => merge(oldState, {
      message: { dirty: true, showError: false },
    }));
  }

  onEmailBlur() {
    this.validateEmail();
    this.setState(oldState => merge(oldState, {
      email: { showError: true },
    }));
  }

  onSubjectBlur() {
    this.validateSubject();
    this.setState(oldState => merge(oldState, {
      subject: { showError: true },
    }));
  }

  onMessageBlur() {
    this.validateMessage();
    this.setState(oldState => merge(oldState, {
      message: { showError: true },
    }));
  }

  resetSubmit() {
    this.setState(
      oldState => merge(oldState, {
        successSend: false,
        message: {
          value: '',
          showError: false,
          dirty: false,
          valid: false,
        },
        email: {
          showError: false,
          dirty: false,
          valid: false,
        },
        subject: {
          value: '',
          showError: false,
          dirty: false,
          valid: false,
        },
      }),
      this.onCaptchaExpired
    );
  }

  onSubmitForm(e) {
    e.preventDefault();

    if (!(this.validateEmail() && this.validateMessage())) {
      this.setState(oldState => merge(oldState, {
        email: { showError: true },
        message: { showError: true },
      }));
      return;
    }

    if (this.recaptcha) {
      this.recaptcha.execute();
    } else {
      this.submitForm();
    }
  }

  onCaptchaExpired() {
    if (this.recaptcha) {
      this.recaptcha.reset()
    }
  }

  submitForm(token) {
    const { email, subject, message } = this.state;
    const self = this;

    const formData = new FormData();

    formData.append('email', email.value);
    formData.append('subject', subject.value);
    formData.append('message', message.value);

    if (token) {
      formData.append('recaptcha_token', token);
    }

    this.setState(oldState => merge(oldState, { isSubmitted: true }));

    const contactUsAxiosConfig = this.api.contactUsConfig({
      data: formData,
    });

    axios(contactUsAxiosConfig)
      .then(response => {
        if (response.status === 200) {
          self.setState(oldState => merge(oldState, {
            successSend: true,
            isError: false,
          }));
        }
      })
      .catch(error => {
        this.onCaptchaExpired();

        if (!error.response) {
          this.setState(oldState => merge(oldState, {
            isError: true,
            successSend: false,
          }));

          return;
        }

        const { errors } = error.response.data;
        let errorState;

        if (errors.field_errors) {
          const reduceFn = (acc, key) => merge(acc, {
            error: {
              general: this.backendErrors.array2messages(errors.general),
              [camelCase(key)]: this.backendErrors.array2messages(errors.field_errors[key]),
            },
          });

          errorState = reduce(Object.keys(errors.field_errors), reduceFn, {});
        }

        this.setState(state => merge(state, errorState));
      });
  }

  validateSubject() {
    const { subject } = this.state;
    const { t } = this.props;

    let subjectState;

    if (subject.value.length < 79) {
      subjectState = {
        subject: {
          valid: true,
          showError: false,
          dirty: true,
        },
        errors: {
          subject: [],
        },
      };
    } else {
      subjectState = {
        subject: {
          valid: false,
          showError: true,
          dirty: true,
        },
        errors: {
          subject: [t('page_contact_us:subject_more_than_78')],
        },
      };
    }

    this.setState(oldState => merge(oldState, subjectState));
  }

  validateEmail() {
    const { email } = this.state;
    const { t } = this.props;

    const validate = e => {
      const re = /\S+@\S+\.\S+/;
      return re.test(String(e).toLowerCase());
    };

    const isValid = validate(email.value);

    let error;

    if (isEmpty(email.value)) {
      error = [t('page_contact_us:email_required')];
    } else if (isValid) {
      error = [];
    } else {
      error = [t('page_contact_us:email_not_valid')];
    }

    this.setState(oldState => merge(oldState, {
      errors: { email: error },
      email: { valid: isValid },
    }));
    return isValid;
  }

  validateMessage() {
    const { message } = this.state;
    const { t } = this.props;

    const isValid = !isEmpty(message.value);
    const error = isValid ? [] : [t('page_contact_us:message_required')];

    this.setState(oldState => merge(oldState, {
      errors: { message: error },
      message: { valid: isValid },
    }));
    return isValid;
  }

  onEmailInput(e) {
    const { value } = e.target;

    this.setState(oldState => merge(oldState, {
      email: { value, dirty: true },
    }));
  }

  onSubjectInput(e) {
    const { value } = e.target;

    this.setState(oldState => merge(oldState, {
      subject: { value, dirty: true },
    }));
  }

  onMessageInput(e) {
    const { value } = e.target;
    const { t } = this.props;

    const error = value !== '' ? [] : [t('page_contact_us:message_required')];

    this.setState(oldState => merge(oldState, {
      errors: { message: error },
      message: { value, dirty: true },
    }));
  }

  render() {
    const { sitekey, recaptchaEnabled, brand, t } = this.props;
    const { successSend, isError, errors, message, email, subject } = this.state;

    const Message = ({ text }) => (
      <div
        className={cl('contact-message', {
          'contact-message__error': isError,
        })}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );

    return (
      <div className='contact-page'>
        <SiteTitle brandName={brand.name} title={t('page_contact_us:contact_us')} />
        <div className='contact-wrapper contact-wrapper_bg_gray contact-wrapper_borderBottom'>
          <Grid className='contact-header'>
            <Cell desktop={12} tablet={8} mobile={4}>
              <Grid className='inner-grid'>
                <Cell desktop={1} />
                <Cell desktop={10} tablet={8} mobile={4}>
                  <div className='contact-innerWrapper contact-innerWrapper_header'>
                    <h1 className='contact-title_h1'>
                      {t('page_contact_us:h1')}
                    </h1>
                    {successSend
                      ? (
                        <div className='contact-headerSuccess__grid' data-test='message-successful-submit'>
                          <div className='contact-headerSuccess__title'>
                            {t('page_contact_us:message_was_send')}
                          </div>
                          <div className='contact-headerSuccess__text'>
                            {t('page_contact_us:message_was_send_text')}
                          </div>
                          <div className='contact-headerSuccess__bottomSection' onClick={this.resetSubmit}>
                            <div className='contact-headerSuccess__back'>
                              {t('page_contact_us:send_another_message')}
                            </div>
                          </div>
                        </div>
                      )
                      : (
                        <div className='contact-header__grid'>
                          <form className='contact-header__text' onSubmit={this.onSubmitForm}>
                            {isError && (
                              <div className='contact-blockMessage'>
                                <Message text={t('core:general_error')} />
                              </div>
                            )}
                            <div className='contact-block'>
                              <label htmlFor='email'>
                                {t('page_contact_us:email')}
                              </label>
                              <InputField
                                defaultValue={email.value}
                                autoComplete='off'
                                onInput={this.onEmailInput}
                                onFocus={this.onEmailFocus}
                                onBlur={this.onEmailBlur}
                                name='email'
                                hasError={email.showError && !email.valid}
                                data-test='email'
                              />
                              {email.showError && !email.valid && <FiledError errorMsg={errors.email} />}
                            </div>
                            <div className='contact-block'>
                              <label htmlFor='subject'>
                                <span>
                                  {t('page_contact_us:subject')}
                                  {' '}
                                </span>
                                <span>
                                  {`(${t('page_contact_us:optional')})`}
                                </span>
                              </label>
                              <InputField
                                onInput={this.onSubjectInput}
                                onFocus={this.onSubjectFocus}
                                onBlur={this.onSubjectBlur}
                                name='subject'
                                hasError={subject.showError && !subject.valid}
                                data-test='subject'
                              />
                              {subject.showError && !subject.valid && <FiledError errorMsg={errors.subject} />}
                            </div>
                            <div className='contact-block'>
                              <label htmlFor='textarea'>
                                <span>
                                  {t('page_contact_us:message')}
                                </span>
                              </label>
                              <TextArea
                                rows='6'
                                name='message'
                                defaultValue={message.value}
                                onInput={this.onMessageInput}
                                onFocus={this.onMessageFocus}
                                onBlur={this.onMessageBlur}
                                hasError={message.showError && !message.valid}
                                data-test='message'
                              />
                              {message.showError && !message.valid && <FiledError errorMsg={errors.message} />}
                            </div>
                            <div className='contact-block'>
                              <button
                                className='contact-button contact-button_dark contact-button_submit'
                                type='submit'
                                data-test='send-button'
                              >
                                {t('page_contact_us:send')}
                              </button>
                            </div>
                          </form>
                        </div>
                      )}
                      {recaptchaEnabled === 'true' && (
                        <Recaptcha
                          sitekey={sitekey}
                          ref={e => this.recaptcha = e}
                          size='invisible'
                          onChange={this.submitForm}
                          onExpired={this.onCaptchaExpired}
                        />
                      )}
                  </div>
                </Cell>
                <Cell desktop={1} />
              </Grid>
            </Cell>
            <Cell />
          </Grid>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(ContactUsBody);
