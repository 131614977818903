import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get'
import Layout from '../layouts';
import PageHeader from '../components/PageHeader';
import PageFooter from '../components/PageFooter';
import PageContent from '../styled/PageContent';
import ContactUsBody from '../components/ContactUsPage/ContactUsBody';

class ContactPage extends React.Component {
  render() {
    const {
      invisibleRecaptchaSiteKey,
      recaptchaEnabled,
      gatsbyUrl,
      pythonUrl,
      brand,
      affiliateUrl
    } = get(this.props, 'data.site.siteMetadata');
    const { location } = this.props;

    return (
      <Layout location={location}>
        <div>
          <PageHeader
            brand={brand}
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
          />
          <PageContent>
            <ContactUsBody
              gatsbyUrl={gatsbyUrl}
              pythonUrl={pythonUrl}
              sitekey={invisibleRecaptchaSiteKey}
              recaptchaEnabled={recaptchaEnabled}
              brand={brand}
            />
          </PageContent>
          <PageFooter
            gatsbyUrl={gatsbyUrl}
            pythonUrl={pythonUrl}
            brand={brand}
            location={location}
            affiliateUrl={affiliateUrl}
          />
        </div>
      </Layout>
    );
  }
}

const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        brand {
          name
          nameCom
          twitter
          facebook
        }
        gatsbyUrl
        pythonUrl
        invisibleRecaptchaSiteKey
        recaptchaEnabled
        affiliateUrl
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={data => <ContactPage data={data} location={location} />}
  />
);
